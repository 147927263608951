import React from "react";
import { datadogRum } from "@datadog/browser-rum";

export const PerformanceObserverContext = React.createContext();

const PerformanceObserverContextProvider = ({ children }) => {
  const [loadTime, setLoadTime] = React.useState(null);
  const [pageLoadTime, setPageLoadTime] = React.useState(null);

  const startLoadTime = React.useCallback(() => {
    const load = Date.now();
    setLoadTime(load);
    return load;
  }, []);

  const recordPageLoadTime = React.useCallback(() => {
    datadogRum.addTiming('signup_page_load_time');
    const pageLoad = Date.now()
    setPageLoadTime(pageLoad);
  }, []);

  const setEndLoadTime = React.useCallback((time) => {
    const endTime = Date.now();
    const load = endTime - time;
    setLoadTime(load);
    return load;
  }, []);

  const clearLoadTime = React.useCallback(() => {
    setLoadTime(null);
  }, []);

  const value = React.useMemo(
    () => ({ startLoadTime, pageLoadTime, setEndLoadTime, loadTime, recordPageLoadTime, clearLoadTime }),
    [startLoadTime, pageLoadTime, setEndLoadTime, loadTime, recordPageLoadTime, clearLoadTime]
  );

  return <PerformanceObserverContext.Provider value={value}>{children}</PerformanceObserverContext.Provider>;
}

export default PerformanceObserverContextProvider;

export {
  PerformanceObserverContextProvider
}
